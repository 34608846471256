<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="4"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.time_allocation.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`Incluir en el ${$getVisibleNames(
        'manual.total_hours_sum_to_chronological',
        true,
        'Total de Horas (Pedagógicas + Cronológicas)'
      )}:`"
      label-for="input-counts_towards_credits"
      label-cols="4"
      label-cols-sm="4"
      class="label"
    >
      <b-form-select
        id="input-counts_towards_credits"
        name="input-counts_towards_credits"
        v-model="$v.time_allocation.counts_towards_credits.$model"
        :options="[
          { value: true, text: 'SI' },
          { value: false, text: 'NO' },
        ]"
        :state="validateState('counts_towards_credits')"
        aria-describedby="input-counts_towards_credits-live-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-counts_towards_credits-live-feedback"
        >Este campo es requerido.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Tipo de Horas:"
      label-for="input-uses_module_minutes"
      label-cols="4"
      label-cols-sm="4"
      class="label"
    >
      <b-form-select
        v-if="
          institution &&
          institution.internal_use_id == 'duoc_uc' &&
          time_allocation.internal_use_id != null
        "
        size="sm"
        :value="time_allocation.uses_module_minutes"
        :options="[
          { value: true, text: 'Pedagógicas' },
          { value: false, text: 'Cronológicas' },
        ]"
        disabled
      >
      </b-form-select>
      <b-form-select
        v-else
        id="input-uses_module_minutes"
        name="input-uses_module_minutes"
        v-model="$v.time_allocation.uses_module_minutes.$model"
        :options="[
          { value: true, text: 'Pedagógicas' },
          { value: false, text: 'Cronológicas' },
        ]"
        :state="validateState('uses_module_minutes')"
        aria-describedby="input-uses_module_minutes-live-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-uses_module_minutes-live-feedback"
        >Este campo es requerido.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="% de asistencia requerido:"
      label-for="input-attendance_requirement"
      label-cols="4"
      label-cols-sm="4"
      class="label"
    >
      <b-form-input
        id="input-attendance_requirement"
        name="input-attendance_requirement"
        type="number"
        v-model="$v.time_allocation.attendance_requirement.$model"
        :state="validateState('attendance_requirement')"
        aria-describedby="input-attendance_requirement-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-attendance_requirement-live-feedback"
        >Este campo debe ser mayor o igual a cero y menor o igual a
        100.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Orden:"
      label-for="input-order"
      label-cols="4"
      label-cols-sm="4"
      class="label"
    >
      <b-form-input
        id="input-order"
        name="input-order"
        type="number"
        v-model="$v.time_allocation.order.$model"
        :state="validateState('order')"
        aria-describedby="input-order-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-order-live-feedback"
        >Este campo debe ser mayor o igual a cero y menor o igual a
        100.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`Asociar a ${$getVisibleNames(
        'mesh.studyenvironment',
        false,
        'Ambiente de Aprendizaje'
      )}:`"
      label-for="input-has_study_environments"
      label-cols="4"
      label-cols-sm="4"
      class="label"
    >
      <b-form-select
        id="input-has_study_environments"
        name="input-has_study_environments"
        v-model="$v.time_allocation.has_study_environments.$model"
        :options="[
          { value: true, text: 'SI' },
          { value: false, text: 'NO' },
        ]"
        :state="validateState('has_study_environments')"
        aria-describedby="input-has_study_environments-live-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-has_study_environments-live-feedback"
        >Este campo es requerido.</b-form-invalid-feedback
      >
    </b-form-group>
    <SelectedStudyEnvironmentTimeAllocation
      v-if="
        institution &&
        institution.show_study_environment_menu &&
        time_allocation.has_study_environments
      "
      :object_id="time_allocation.id"
      :ViewTimeAllocation="true"
      @update_study_environment="updateStudyEnvironment"
      @remove_study_environment="removeStudyEnvironment"
    ></SelectedStudyEnvironmentTimeAllocation>
    <b-form-group
      v-if="!isNaN(this.time_allocation.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="time_allocation"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.time_allocation.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteTimeAllocation"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "TimeAllocationForm",
  mixins: [validationMixin],
  components: {
    SelectedStudyEnvironmentTimeAllocation: () =>
      import("@/components/reusable/SelectedStudyEnvironmentTimeAllocation"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    maxOrder: {
      type: Number,
    },
    TimeAllocation: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          order: this.maxOrder ? this.maxOrder : null,
          counts_towards_credits: false,
          has_study_environments: true,
          uses_module_minutes: false,
          attendance_requirement: null,
          internal_use_id: null,
          updated_by: null,
          update_date: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      time_allocation: { ...this.TimeAllocation },
      tmp_study_environments: [],
    };
  },
  validations: {
    time_allocation: {
      name: {
        required,
        minLength: minLength(5),
      },
      counts_towards_credits: {},
      has_study_environments: {},
      uses_module_minutes: {},
      attendance_requirement: {
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      order: { required, minValue: minValue(0) },
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      studyEnvironmentsTimeAllocation: names.STUDY_ENVIRONMENTS_TIME_ALLOCATION,
    }),
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.time_allocation[name];
      return $dirty ? !$error : null;
    },
    updateStudyEnvironment(study_environments) {
      if (isNaN(this.time_allocation.id)) {
        study_environments.forEach((study_environment) => {
          if (!this.tmp_study_environments.includes(study_environment))
            this.tmp_study_environments.push(study_environment);
        });
      } else if (!isNaN(this.time_allocation.id)) {
        this.updateStudyEnvironmentTimeAllocation(study_environments);
      }
    },
    async updateStudyEnvironmentTimeAllocation(study_environments) {
      for await (let study_environment of study_environments) {
        await this.$store.dispatch(
          names.POST_STUDY_ENVIRONMENT_TIME_ALLOCATION,
          {
            study_environment: study_environment,
            time_allocation: this.time_allocation.id,
          }
        );
      }
    },
    removeStudyEnvironment(study_environment) {
      if (isNaN(this.time_allocation.id)) {
        const index = this.tmp_study_environments.findIndex(
          (x) => x == study_environment
        );
        if (index != -1) this.tmp_study_environments.splice(index, 1);
      } else {
        const study_environment_time_allocation =
          this.studyEnvironmentsTimeAllocation.find(
            (x) =>
              x.time_allocation == this.time_allocation.id &&
              x.study_environment == study_environment
          );
        if (study_environment_time_allocation)
          this.$store.dispatch(
            names.DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION,
            study_environment_time_allocation.id
          );
      }
    },
    save() {
      this.$v.time_allocation.$touch();
      if (this.$v.time_allocation.$anyError) {
        return;
      }
      if (isNaN(this.time_allocation.id)) this.createTimeAllocation();
      else this.updateTimeAllocation();
    },
    createTimeAllocation() {
      this.$store
        .dispatch(names.POST_TIME_ALLOCATION, this.time_allocation)
        .then((response) => {
          this.time_allocation.id = response.id;
          if (
            this.tmp_study_environments.length > 0 &&
            this.time_allocation.has_study_environments
          )
            this.updateStudyEnvironmentTimeAllocation(
              this.tmp_study_environments
            );
          toast(
            String(
              this.$getVisibleNames(
                "mesh.timeallocation",
                false,
                "División de tiempo"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateTimeAllocation() {
      this.$store
        .dispatch(names.UPDATE_TIME_ALLOCATION, this.time_allocation)
        .then((response) => {
          if (!response.has_study_environments) {
            this.studyEnvironmentsTimeAllocation
              .filter((x) => x.time_allocation == response.id)
              .forEach((study_environment_time_allocation) => {
                this.$store.dispatch(
                  names.DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION,
                  study_environment_time_allocation.id
                );
              });
          }
          toast(
            String(
              this.$getVisibleNames(
                "mesh.timeallocation",
                false,
                "División de tiempo"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteTimeAllocation() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.timeallocation",
          false,
          "División de tiempo"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_TIME_ALLOCATION,
            this.time_allocation.id
          );
          this.$emit("deleted", this.time_allocation);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>